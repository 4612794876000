import { Container } from './styles';

interface CardRowProps {
  id?: string;
}

const CardRow: React.FC<CardRowProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default CardRow;

import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/arthur-corrida-sign-in-background.jpeg';

export const Container = styled.div`
  height: 100vh;

  background: #312e38;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 45%;
  //width: 156%;
  //max-width: 1030px;
  //max-width: 500px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
  // background-size: contain;
  // margin: 170px;
  background-blend-mode: luminosity;
  filter: grayscale(100%); /* Current draft standard */
  -webkit-filter: grayscale(100%); /* New WebKit */
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%); /* Not yet supported in Gecko, Opera or IE */
  filter: url(resources.svg#desaturate); /* Gecko */
  filter: gray; /* IE */
  -webkit-filter: grayscale(1); /* Old WebKit */
  background-position: top;
  margin-top: -125px;
  background-position-x: -100px;
  background-size: unset;
`;

import styled from 'styled-components';
import { Button as Btn, Tooltip } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

export const Container = styled.div`
  input {
    flex: 1;
    background: #fff;
    border: 0;
    color: #333;

    &::placeholder {
      color: #808080;
    }

    padding: 16px;
    width: 100%;
  }
`;

export const Content = styled.div``;

export const Button = styled(Btn)`
  margin: 20px 0 15px !important;
  background-color: #00a65a !important;
  color: #fff !important;
`;

export const EditIcon = styled(EditOutlined)`
  display: table-cell;
  vertical-align: middle;
`;

export const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    background: navy;
  }
`;

export const AlunoItem = styled.div`
  display: flex;
  margin: 5px;
  align-content: center;
`;

export const Avatar = styled.div`
  display: flex;

  border-radius: 50%;
  background: #fff;
  width: 80px;
  height: 80px;
  border: 1px;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-right: 30px;
  font-size: 36px;
  color: blue;
`;

export const ItemDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 40px;
`;

export const ItemActionColumn = styled.div`
  display: flex;

  justify-content: center;
  margin: 40px;
`;

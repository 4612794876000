/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const BasicAccordionWithDelete = ({ items, onDelete }: any): JSX.Element => {
  return (
    <div>
      {items.map((item: any, index: any) => (
        <Accordion key={index}>
          <AccordionSummary sx={{ marginLeft: 2, marginBottom: 1 }}>
            <IconButton onClick={() => onDelete(item)}>
              <DeleteIcon />
            </IconButton>
            <div style={{ marginLeft: 5 }}>
              <Typography sx={{ marginLeft: 5 }}>{item.title}</Typography>
              <Typography sx={{ marginLeft: 5, fontSize: 14 }}>
                {item.contentDetails}
              </Typography>
              {/* {Object.keys(item.contentDetails).map((key: any, i: number) => (
                <>
                  <div key={i}>
                    <Typography sx={{ marginLeft: 5, fontSize: 14 }}>
                      {`${item.contentDetails[key]}: ${item.contentDetails}`}
                    </Typography>
                  </div>
                  <br />
                </>
              ))} */}
              {/* <Typography sx={{ marginLeft: 5, fontSize: 14 }}>
                {Object.keys(item.contentDetails).map((key: any, i: number) => (
                  <div key={i}>
                    <span>{item.contentDetails[key]}</span>
                    <br />
                  </div>
                ))}
              </Typography> */}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ marginLeft: 12, marginBottom: 3 }}>
              {item.contentDetails}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default BasicAccordionWithDelete;

import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Content, TabStyle, TabButton } from './styles';
import Aluno from './Aluno';
import Treinos from './Treinos/Lista';

interface TabProps {
  idAluno: number;
  tabId: number;
}

const Tabs: React.FC<TabProps> = ({ idAluno, tabId }) => {
  const [currentTab, setCurrentTab] = useState('1');
  const history = useHistory();

  useEffect(() => {
    setCurrentTab(tabId.toString());
  }, [history, idAluno, tabId]);

  const tabs = [
    {
      id: 1,
      tabTitle: 'Dados do Aluno',
      title: '',
      content: <Aluno />,
    },
    {
      id: 2,
      tabTitle: 'Treinos',
      title: '',
      content: <Treinos />,
    },
    {
      id: 3,
      tabTitle: 'Avaliações',
      title: '',
      content: 'Funcionalidade "Avaliações", em breve .',
    },
    {
      id: 4,
      tabTitle: 'Estatísticas',
      title: '',
      content: 'Funcionalidade "Estatísticas", em breve .', // <Estatisticas />,
    },
    {
      id: 5,
      tabTitle: 'Financeiro',
      title: '',
      content: 'Funcionalidade "Financeiros", em breve .',
    },
  ];

  const handleTabClick = (e: any): void => {
    setCurrentTab(e.target.id);
    history.push({
      pathname: `/alunoficha/${idAluno}/${e.target.id}`,
    });
  };

  return (
    <Container>
      <TabStyle>
        {tabs.map((tab, i) => (
          <TabButton
            key={i}
            id={`${tab.id}`}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
          >
            {tab.tabTitle}
          </TabButton>
        ))}
      </TabStyle>
      <Content>
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p className="title">{tab.title}</p>
                <div>{tab.content}</div>
              </div>
            )}
          </div>
        ))}
      </Content>
    </Container>
  );
};

export default Tabs;

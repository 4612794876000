import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';

import {
  Container,
  Content,
  Button,
  ListItem,
  Avatar,
  ItemDataColumn,
  ItemActionColumn,
} from './styles';

import api from '../../services/api';
import CardRow from '../../components/CardRow';
import { useToast } from '../../hooks/toast';

interface Exercicio {
  exercicioId: number;
  nome: string;
  categoria: string;
  grupoMuscular: string;
  usaEquipamento: string;
  equipamento: string;
  fotoEquipamento: string;
  videoExemplo: string;
}

const Exercicios: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [exercicios, setExercicios] = useState([]);
  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    api.get('exercicios').then((response: any) => {
      console.log(response.data);
      setExercicios(response.data);
    });
    setLoading(false);
  }, []);

  const handleEditItem = useCallback(
    (id: number) => {
      history.push({
        pathname: `/exercicios/edit/${id}`,
      });
    },
    [history],
  );

  const handleDeleteItem = useCallback(
    (id: number) => {
      api.delete(`/exercicios/${id}`).then((responseDelete: any) => {
        if (responseDelete.data) {
          addToast({
            type: 'success',
            title: 'Success',
            description: 'O Exercício foi excluído!',
          });
          setLoading(true);
          api.get('exercicios').then((response: any) => {
            setExercicios(response.data);
          });
          setLoading(false);
        }
      });
    },
    [addToast],
  );

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Exercícios</h1>
      <div>
        <Link to="/exercicios/new">
          <Button variant="contained" type="button">
            Novo Exercício
          </Button>
        </Link>
        <input
          name="search"
          type="text"
          placeholder="Pesquisar"
          autoComplete="off"
        ></input>
      </div>

      <Content>
        {exercicios.map((exercicio: Exercicio, index: number) => (
          <CardRow key={index}>
            <ListItem>
              <Avatar style={{ color: 'purple' }}>
                <div>{exercicio.nome[0].toUpperCase()}</div>
              </Avatar>

              <ItemDataColumn>
                <span style={{ marginBottom: 15, marginRight: 70 }}>
                  {exercicio.nome}
                </span>
                <span>CATEGORIA: {exercicio.categoria}</span>
              </ItemDataColumn>
              <ItemDataColumn>
                <span>Equipamento:</span>
                <span>{exercicio.equipamento}</span>
              </ItemDataColumn>
              <ItemActionColumn>
                <div style={{ marginRight: 80 }}>
                  <Link
                    to="#"
                    onClick={() => handleEditItem(exercicio.exercicioId)}
                  >
                    <EditOutlined />
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    onClick={() => handleDeleteItem(exercicio.exercicioId)}
                  >
                    <DeleteOutline />
                  </Link>
                </div>
              </ItemActionColumn>
            </ListItem>
          </CardRow>
        ))}
      </Content>
    </Container>
  );
};
export default Exercicios;

import styled from 'styled-components';
import { Button as Btn, Tooltip } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

export const Container = styled.div`
  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #333;

    &::placeholder {
      color: #808080;
    }

    border-radius: 10px;
    border: 2px solid #232129;
    padding: 16px;
    width: 100%;
    color: #666360;
    border-color: #ccc;
    margin: 15px;
  }
`;

export const Content = styled.div``;

export const Button = styled(Btn)`
  margin: 20px 0 15px !important;
  background-color: #00a65a !important;
  color: #fff !important;
`;

export const EditIcon = styled(EditOutlined)`
  display: table-cell;
  vertical-align: middle;
`;

export const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    background: navy;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex: 1;
  margin: 5px;
  align-items: center;
`;

export const Avatar = styled.div`
  display: flex;
  border-radius: 6%;
  background: #fff;
  width: 80px;
  height: 80px;
  border: 1px;
  align-items: center;
  justify-content: center;
  margin: 5px 20px 5px 20px;
  font-size: 36px;
  color: blue;
`;

export const ItemDataColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-box-pack: center;
  margin: 17px;
`;

export const ItemActionColumn = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 17px;
`;

export const ContainerMenus = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-box-pack: center;
  margin: 17px;

  .MuiButtonBase-root.Mui-disabled {
    /* cursor: default; */
    /* pointer-events: none; */
    background: rgb(160 161 161) !important;
    color: #e8e8e8 !important;
    background-color: gray;
  }
`;

export const ItemMenu = styled.div`
  margin-left: 30px;
`;

export const SpanTreinos = styled.span`
  margin-left: 17px;
  font-size: 24px;
`;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Content,
  Button,
  AlunoItem,
  Avatar,
  ItemDataColumn,
  ItemActionColumn,
} from './styles';
import api from '../../services/api';
import CardRow from '../../components/CardRow';

interface Aluno {
  alunoId: number;
  nome: string;
  telefone: string;
  email: string;
  endereco: string;
  nomeAcademia: string;
  treinoEmCasa: boolean;
  planoAtivo: boolean;
  validadePlano: Date;
  tipoPlano: string;
}

const Customers: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [alunos, setAlunos] = useState<Aluno[]>([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      api.get('alunos').then((response: any) => {
        console.log(`test`, response);
        setAlunos(response.data);
      });
      setLoading(false);
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Alunos</h1>
      <div>
        <Link to="/alunos/new">
          <Button variant="contained" type="button">
            Novo Aluno
          </Button>
        </Link>
        <input type="text" placeholder="Pesquisar"></input>
      </div>

      <Content>
        {alunos.map((aluno: Aluno) => (
          <CardRow key={aluno.alunoId}>
            <AlunoItem>
              <Avatar>
                <div>{aluno?.nome[0]?.toUpperCase()}</div>
              </Avatar>
              <ItemDataColumn>
                <span>{aluno.nome}</span>
                <span>{aluno.telefone}</span>
              </ItemDataColumn>
              <ItemDataColumn>
                <span>
                  Status do Plano: {aluno.planoAtivo ? 'Ativo' : 'Inativo'}
                </span>
                <span>
                  {aluno.tipoPlano ? aluno.planoAtivo : 'Nenhum Plano'}
                </span>
              </ItemDataColumn>

              <ItemActionColumn>
                <Link
                  to={{
                    pathname: `alunos/alunopanel/${aluno?.alunoId}`,
                  }}
                >
                  <Button>Ver Aluno</Button>
                </Link>
              </ItemActionColumn>
            </AlunoItem>
          </CardRow>
        ))}
      </Content>
    </Container>
  );
};
export default Customers;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
// import { treinos } from './treinos carajo';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import {
  Container,
  Content,
  Button,
  ListItem,
  Avatar,
  ItemDataColumn,
  ItemActionColumn,
  ContainerMenus,
  ItemMenu,
  SpanTreinos,
} from './styles';

import api from '../../../services/api';
import CardRow from '../../../components/CardRow';
import { useToast } from '../../../hooks/toast';
import BackButton from '../../../components/BackButton';

interface Treino {
  treinoId: number;
  nome: string;
  dataInico: string;
  dataFim: string;
  ativo: boolean;
  fotoTreino: string;
  exercicios: any[];
}

interface Aluno {
  _id: string;
  nome: string;
  telefone: string;
  email: string;
  endereco: string;
  dataNascimento: string;
  treinoEmCasa: string;
  nomeAcademia: string;
  alunoId: number;
  __v: number;
}

interface AlunoPanelData {
  treinos: Treino[];
  aluno: Aluno;
}

interface ParamTypes {
  id: string;
}

const AlunoPanel: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [alunoPanelData, setAlunoPanelData] = useState<AlunoPanelData>(
    {} as AlunoPanelData,
  );
  const [treinos, setTreinos] = useState<Treino[]>([]);
  const [aluno, setAluno] = useState<Aluno>();
  const [removed, setRemoved] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    let mounted = true;

    const fetchTreinos = async (): Promise<void> => {
      if (mounted) {
        api.get(`treinos/aluno/${id}`).then((response: any) => {
          setAlunoPanelData(response.data);
          setAluno(response.data.aluno);
          setTreinos(response.data.treinos);
          console.log(`alunoPanelData`, response.data);
        });
      }
    };

    fetchTreinos();

    return () => {
      mounted = false;
    };
  }, [id, removed]);

  const handleEditItem = useCallback(
    (idValue: number) => {
      console.log(`idValue: `, idValue);
      history.push({
        pathname: `/alunos/${id}/treinos/edit/${idValue}`,
      });
    },
    [history, id],
  );

  const handleDeleteItem = useCallback(
    (idTreino: number) => {
      api.delete(`/treinos/${idTreino}`).then(async (responseDelete: any) => {
        if (responseDelete.data) {
          addToast({
            type: 'success',
            title: 'Success',
            description: 'O Treino foi excluído!',
          });

          await api.get(`treinos/aluno/${id}`).then((response: any) => {
            setAlunoPanelData(response.data);
            setAluno(response.data.aluno);
            setTreinos(response.data.treinos);
            console.log(`alunoPanelData`, response.data);
          });

          history.push({
            pathname: `/alunos/alunopanel/${id}`,
            state: { tabId: 2 },
          });

          setLoading(false);
        }
      });
    },
    [addToast, history, id],
  );

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Painel do Aluno </h1>
      <BackButton text="Voltar" url={`/alunos`} />
      <h2>Aluno: {alunoPanelData?.aluno?.nome}</h2>
      <span>Academia: {alunoPanelData?.aluno?.nomeAcademia}</span>
      <ContainerMenus>
        <div>
          <Link
            to={{
              pathname: `/alunos/${id}/treinos/new`,
              state: { aluno },
            }}
          >
            <Button variant="contained" type="button">
              Novo Treino
            </Button>
          </Link>
        </div>

        <ItemMenu>
          <Link to={`/alunos/edit/${id}`}>
            <Button variant="contained" type="button">
              Dados do Aluno
            </Button>
          </Link>
        </ItemMenu>
        <ItemMenu>
          <Button
            variant="contained"
            type="button"
            disabled={true}
            disableRipple={true}
            style={{ background: 'gray' }}
            onClick={() => {
              history.push({
                pathname: `alunos/${id}/treinos/new`,
                state: { tabId: 2 },
              });
            }}
          >
            Avaliações
          </Button>
        </ItemMenu>
      </ContainerMenus>

      <Content>
        <div>
          <SpanTreinos>Treinos de {alunoPanelData?.aluno?.nome}</SpanTreinos>
          <input name="search" type="text" placeholder="Pesquisar"></input>
        </div>
        {treinos &&
          treinos?.map((treino: Treino) => (
            <CardRow key={treino.treinoId}>
              <ListItem>
                <Avatar style={{ color: 'purple' }}>
                  <div>{treino.nome[0].toUpperCase()}</div>
                </Avatar>
                <ItemDataColumn>
                  <span style={{ marginBottom: 0, marginRight: 70 }}>
                    {treino.nome}
                  </span>
                  <span>{treino.dataFim?.toString()}</span>
                </ItemDataColumn>
                <ItemDataColumn>
                  <span>Treino Ativo?</span>
                  <span style={{ marginBottom: 0 }}>
                    {treino.ativo ? 'Sim' : 'Não'}
                  </span>
                  <span>Início:</span>
                  <span>{treino.dataInico?.toString()}</span>
                </ItemDataColumn>
                <ItemActionColumn>
                  <div style={{ marginRight: 80 }}>
                    <Link
                      to="#"
                      onClick={() => handleEditItem(treino.treinoId)}
                    >
                      <EditOutlined />
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="#"
                      onClick={() => handleDeleteItem(treino.treinoId)}
                    >
                      <DeleteOutline />
                    </Link>
                  </div>
                </ItemActionColumn>
              </ListItem>
            </CardRow>
          ))}
      </Content>
    </Container>
  );
};
export default AlunoPanel;

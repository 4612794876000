/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
// import { treinos } from './treinos';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import {
  Container,
  Content,
  Button,
  ListItem,
  Avatar,
  ItemDataColumn,
  ItemActionColumn,
} from './styles';

import api from '../../../../../services/api';
import CardRow from '../../../../../components/CardRow';
import { useToast } from '../../../../../hooks/toast';

interface Treino {
  treinoId: number;
  nome: string;
  dataInico: string;
  dataFim: string;
  ativo: boolean;
  fotoTreino: string;
}

interface ParamTypes {
  id: string;
  tabId: string;
}

const TreinosLista: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [treinos, setTreinos] = useState<Treino[]>([]);
  const history = useHistory();
  const { addToast } = useToast();

  const { id, tabId } = useParams<ParamTypes>();

  useEffect(() => {
    setLoading(true);
    api.get(`treinos/aluno/${id}`).then((response: any) => {
      setTreinos(response.data);
    });
    setLoading(false);
  }, [id]);

  const handleEditItem = useCallback(
    (idValue: number) => {
      history.push({
        pathname: `/treinos/edit/${idValue}`,
      });
    },
    [history],
  );

  // const handleDeleteItem = useCallback(
  //   (idValue: number) => {
  //     history.push({
  //       pathname: `/treinos/delete/${idValue}`,
  //     });
  //   },
  //   [history],
  // );

  const handleDeleteItem = useCallback(
    (idTreino: number) => {
      api.delete(`/treinos/${idTreino}`).then((responseDelete: any) => {
        if (responseDelete.data) {
          addToast({
            type: 'success',
            title: 'Success',
            description: 'O Treino foi excluído!',
          });
          setLoading(true);
          api.get(`treinos/aluno/${id}`).then((response: any) => {
            setTreinos(response.data);
          });
          history.push({
            pathname: `/alunoficha/${id}/${tabId}`,
            state: { tabId: 2 },
          });

          setLoading(false);
        }
      });
    },
    [addToast, history, id, tabId],
  );

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>Treinos</h1>
      <div>
        <Link to={`${tabId}/treinos/new`}>
          <Button variant="contained" type="button">
            Novo Treino
          </Button>
        </Link>
        <input name="search" type="text" placeholder="Pesquisar"></input>
      </div>

      <Content>
        {treinos.map((treino: Treino) => (
          <CardRow key={treino.treinoId}>
            <ListItem>
              <Avatar style={{ color: 'purple' }}>
                <div>{treino.nome[0].toUpperCase()}</div>
              </Avatar>
              <ItemDataColumn>
                <span style={{ marginBottom: 0, marginRight: 70 }}>
                  {treino.nome}
                </span>
                <span>{treino.dataFim.toString()}</span>
              </ItemDataColumn>
              <ItemDataColumn>
                <span>Treino Ativo?</span>
                <span style={{ marginBottom: 0 }}>
                  {treino.ativo ? 'Sim' : 'Não'}
                </span>
                <span>Início:</span>
                <span>{treino.dataInico?.toString()}</span>
              </ItemDataColumn>
              <ItemActionColumn>
                <div style={{ marginRight: 80 }}>
                  <Link to="#" onClick={() => handleEditItem(treino.treinoId)}>
                    <EditOutlined />
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    onClick={() => handleDeleteItem(treino.treinoId)}
                  >
                    <DeleteOutline />
                  </Link>
                </div>
              </ItemActionColumn>
            </ListItem>
          </CardRow>
        ))}
      </Content>
    </Container>
  );
};
export default TreinosLista;

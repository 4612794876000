import styled from 'styled-components';
import { Button as Btn, Tooltip } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

import ReactSelect from 'react-select';

// export const Container = styled.div`
//   input {
//     flex: 1;
//     background: #fff;
//     border: 0;
//     color: #333;

//     &::placeholder {
//       color: #808080;
//     }

//     padding: 16px;
//     width: 100%;
//   }
// `;

export const Container = styled.div``;

export const Content = styled.div``;

export const Button = styled(Btn)`
  margin: 20px 0 15px !important;
  background-color: #00a65a !important;
  color: #fff !important;
`;

export const ButtonAddExercises = styled(Btn)`
  margin: 20px 0 15px !important;
  background-color: #00a65a !important;
  color: #fff !important;
`;

export const EditIcon = styled(EditOutlined)`
  display: table-cell;
  vertical-align: middle;
`;

export const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    background: navy;
  }
`;

export const AlunoItem = styled.div`
  display: flex;
  margin: 5px;
  align-content: center;
`;

export const Avatar = styled.div`
  display: flex;

  border-radius: 50%;
  background: #fff;
  width: 80px;
  height: 80px;
  border: 1px;
  align-items: center;
  justify-content: center;
  margin: 10px;
  margin-right: 30px;
  font-size: 36px;
  color: blue;
`;

export const ItemDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 40px;
`;

export const ItemActionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
`;

export const AddExercises = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flext-start;
  align-items: stretch;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flext-start;
`;

export const ContainerExecucao = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-items: flex-end;
  gap: 5%;
`;

export const ContainerSelect = styled.div`
  border-radius: 4px;
  border: 2px solid #ccc;
  padding: 5.5px 16px;
  width: 100%;
  color: #666360;

  display: flex;

  background: #fff;
`;

export const SelectExercicio = styled(ReactSelect)`
  flex: 1;

  .${props => props.classNamePrefix}__control {
    border: 0;
    box-shadow: none;
  }

  .${props => props.classNamePrefix}__value-container {
    padding: 0;
  }

  .${props => props.classNamePrefix}__placeholder {
    margin: 0;
  }

  img {
    max-width: 40px;
    max-height: 40px;
  }

  .css-yk16xz-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: none;
    border-width: 0px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
  }

  .css-1pahdxg-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #2684ff;
    border-radius: 4px;
    border-style: none;
    border-width: 1px;
    box-shadow: 0 0 0 0 #fff;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    /* box-sizing: border-box; */
  }
`;

export const ContainerSaveCancelButtons = styled.div`
  margin-left: 10px;
`;

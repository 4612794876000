import React, { useCallback, useEffect, useRef } from 'react';
import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import FormExercicio from '../Form';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';

interface Exercicio {
  nome: string;
  categoria: string;
  grupoMuscular: string;
  usaEquipamento: boolean;
  equipamento: string;
  fotoEquipamento: string;
  videoExemplo: string;
}

interface ParamTypes {
  id: string;
}

const EditExercicios: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    api.get(`exercicios/${id}`).then((response: any) => {
      console.log(`test`, response);

      formRef.current?.setFieldValue('nome', response.data.nome);
      formRef.current?.setFieldValue('categoria', response.data.categoria);
      formRef.current?.setFieldValue(
        'grupoMuscular',
        response.data.grupoMuscular,
      );
      formRef.current?.setFieldValue(
        'usaEquipamento',
        response.data.usaEquipamento,
      );

      formRef.current?.setFieldValue('usaEquipamento', {
        value: response.data.usaEquipamento,
        label: response.data.usaEquipamento === '1' ? 'Sim' : 'Não',
      });

      formRef.current?.setFieldValue('equipamento', response.data.equipamento);
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: Exercicio) => {
      console.log('data', data);
      try {
        // const company =
        //   formRef.current?.getFieldRef('company').select?.state?.selectValue[0];

        // const sector =
        //   formRef.current?.getFieldRef('sector').select?.state?.selectValue[0];

        // const region =
        //   formRef.current?.getFieldRef('region').select?.state?.selectValue[0];

        // const employee =
        //   formRef.current?.getFieldRef('employee').select?.state
        //     ?.selectValue[0];

        // const revenue =
        //   formRef.current?.getFieldRef('revenue').select?.state?.selectValue[0];

        formRef.current?.setErrors({});

        // const schema = Yup.object().shape({
        //   nome: Yup.string().required('Customer name is required'),
        // });

        // await schema.validate(data, {
        //   abortEarly: false,
        // });

        const editExercicio = data;

        const response = await api.put(`/exercicios/${id}`, editExercicio);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'Exercício criado com sucesso!',
        });

        history.push('/exercicios', {
          customerId: response.data.customerId,
          name: response.data.name,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          console.log('err', err);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Registration Error',
          description: 'Erro ao criar exercicio. por favor, tente novamente.',
        });
      }
    },
    [addToast, history, id],
  );

  return (
    <Container>
      <h1>Editar exercício</h1>
      <FormExercicio
        formRef={formRef}
        handleSubmit={handleSubmit}
        buttonText="Edit"
      ></FormExercicio>
    </Container>
  );
};
export default EditExercicios;

import styled from 'styled-components';

export const Container = styled.div`
  // background-color: black;

  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;

  .MuiContainer-root {
    width: 100%;
    display: unset;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  background: #fff;
`;

export const Content = styled.div``;

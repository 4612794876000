import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';

import FormAluno from '../Form';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/FormInput';
import Select from '../../../components/SelectForm';
import Button from '../../../components/Button';

import { Container } from './styles';
import BackButton from '../../../components/BackButton';

interface Aluno {
  nome: string;
  telefone: string;
  endereco: string;
  dataNascimento: Date;
  email: string;
  treinoEmCasa: SelectType;
  nomeAcademia: number;
}

interface SelectType {
  value: number;
  label: string;
}

const CreateAluno: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const [dateFromDb, setDateFromDb] = useState('');
  const [editEnabled, setEditEnabled] = useState<boolean>(true);

  const handleSubmit = useCallback(
    async (data: Aluno) => {
      try {
        formRef.current?.setErrors({});

        const novoAluno = {
          ...data,
          treinoEmCasa: !!data.treinoEmCasa,
          dataNascimento: dateFromDb,
        };

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do aluno é obrigatório'),
        });

        await schema.validate(novoAluno, {
          abortEarly: false,
        });

        const response = await api.post('/alunos', novoAluno);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Aluno cadastrado com sucesso!',
        });

        history.push('/alunos', {
          alunoId: response.data.alunoId,
          nome: response.data.nome,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: `Registration Error`,
          description:
            'Ocorreu um erro ao criar aluno, por favor tente novamente.',
        });
      }
    },
    [addToast, dateFromDb, history],
  );

  return (
    <Container>
      <h1>Novo aluno</h1>
      <FormAluno
        formRef={formRef}
        handleSubmit={handleSubmit}
        editEnabled={editEnabled}
        buttonText="Create"
        dateFromDb={dateFromDb}
        setDateFromDb={setDateFromDb}
      ></FormAluno>
    </Container>
  );
};

export default CreateAluno;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';

import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import { Container, ContainerSaveCancelButtons } from './styles';
import api from '../../../services/api';

import Input from '../../../components/FormInputDefault';
import Select from '../../../components/SelectForm';

const treinoEmCasaOptions: SelectType[] = [
  { value: 0, label: 'Sim' },
  { value: 1, label: 'Não' },
];

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: [];
  editEnabled?: boolean;
  setEditEnabled?: any;
  handleSubmit: SubmitHandler;
  dateFromDb: string;
  setDateFromDb: any;
}

interface SelectType {
  value: number;
  label: string;
}

const FormAluno: React.FC<Props> = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedTreinoEmCasa, setSelectedTreinoEmCasa] = useState<SelectType>({
    value: 1,
    label: 'Não',
  });

  const handleDateChange = useCallback(
    e => {
      e.preventDefault();
      const { name, value } = e.target;

      props.setDateFromDb(value);
    },
    [props],
  );

  const handleTreinoEmCasaChange = useCallback(value => {
    setSelectedTreinoEmCasa(value);
  }, []);

  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h3>Nome</h3>
        <Input
          disabled={!props.editEnabled}
          isDisabled={!props.editEnabled}
          type="text"
          name="nome"
          placeholder="Digite o nome completo do aluno"
        />

        <h3>Telefone</h3>
        <Input
          disabled={!props.editEnabled}
          isDisabled={!props.editEnabled}
          type="text"
          name="telefone"
          placeholder="(00) 9 0000-0000"
        />

        <h3>Email</h3>
        <Input
          type="text"
          name="email"
          placeholder="Ex.: aluno@forfit.com"
          disabled={!props.editEnabled}
          isDisabled={!props.editEnabled}
        />

        <h3>Endereço</h3>
        <Input
          disabled={!props.editEnabled}
          isDisabled={!props.editEnabled}
          type="text"
          name="endereco"
          placeholder="Digite o endereço do aluno"
        />

        <h3>Data Nascimento</h3>

        <Input
          type="date"
          isDisabled={!props.editEnabled}
          disabled={!props.editEnabled}
          name="dataNascimento"
          value={props.dateFromDb}
          onChange={handleDateChange}
        />

        <h3>Treino em casa?</h3>
        <Select
          disabled={!props.editEnabled}
          isDisabled={!props.editEnabled}
          options={treinoEmCasaOptions}
          value={selectedTreinoEmCasa}
          onChange={(e: any) => handleTreinoEmCasaChange(e)}
          name="treinoEmCasa"
          placeholder="Sim ou Não"
        />

        <h3>Nome da Academia</h3>
        <Input
          disabled={!props.editEnabled}
          isDisabled={!props.editEnabled}
          type="text"
          name="nomeAcademia"
          placeholder="Digite o nome da academia onde o aluno treina"
        />

        {/* <h2>Video - Execução</h2>
        <Input type="text" name="videoExemplo" placeholder="link do vídeo" /> */}

        {props.editEnabled && (
          <ContainerSaveCancelButtons>
            <Button type="submit" width="200px" marginRight="40px">
              Salvar
            </Button>

            {props.buttonText === 'Edit' && (
              <Button
                type="button"
                width="200px"
                onClick={() => props.setEditEnabled(false)}
              >
                Cancelar
              </Button>
            )}

            {props.buttonText === 'Create' && (
              <Button
                type="button"
                width="200px"
                onClick={() => {
                  history.push('/alunos');
                }}
              >
                Cancelar
              </Button>
            )}
          </ContainerSaveCancelButtons>
        )}
      </Form>
    </Container>
  );
};
export default FormAluno;

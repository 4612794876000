import styled from 'styled-components';

interface ButtonProps {
  width?: string;
  height?: string;
  marginRight?: string;
  shadow?: boolean;
  disabled: boolean;
}

export const Container = styled.div``;

export const Content = styled.div`
  padding: 50px 80px;
  background-color: #f0f0f0;
  font-weight: 300;
  line-height: 30px;
  font-size: 16px;
  text-align: justify;
`;

export const TabStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabButton = styled.button<ButtonProps>`
  background-color: lightgray;
  border: none;
  color: #ADADAD
  cursor: pointer;
  padding: 15px;
  width: 100%;
  background-color: #1D1D1D;
  transition: all 0.5s ease-out;

  &:hover {
    color: white;
    background-color: #888888;
  }

  background: ${props =>
    props.disabled
      ? 'linear-gradient(245.39deg, #9199b0 -35.14%,#05060870 143.06%)'
      : 'background-color: rgba(255, 255, 255, 0.1)'};
  color: ${props => (props.disabled ? 'white' : '#888888')};

  .title {
    color: black;
    font-size: medium;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }

  /* Only round the top corners */
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from '../../../components/Button';
import { Container } from './styles';
import api from '../../../services/api';

import Input from '../../../components/FormInputDefault';
import Select from '../../../components/SelectForm';

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  initialData?: [];
  handleSubmit: SubmitHandler;
}

interface SelectType {
  value: number;
  label: string;
}

const usaEquipamentoOptions: SelectType[] = [
  { value: 0, label: 'Não' },
  { value: 1, label: 'Sim' },
];

const FormExercicios: React.FC<Props> = props => {
  const [loading, setLoading] = useState(false);
  const [selectedUsaEquipamento, setSelectedUsaEquipamento] =
    useState<SelectType>({
      value: 1,
      label: 'Sim',
    });

  useEffect(() => {
    setLoading(true);
    api.get('exercicios').then((response: any) => {
      console.log(`test`, response);
    });
    setLoading(false);
  }, []);

  const handleTreinoEmCasaChange = useCallback(value => {
    setSelectedUsaEquipamento(value);
  }, []);

  return (
    <Container>
      <Form
        ref={props.formRef}
        initialData={props.initialData}
        onSubmit={props.handleSubmit}
      >
        <h2>Nome</h2>
        <Input type="text" name="nome" placeholder="Nome do exercício" />

        <h2>Categoria</h2>
        <Input type="text" name="categoria" placeholder="Categoria" />

        <h2>Grupo Muscular</h2>
        <Input type="text" name="grupoMuscular" placeholder="Grupo muscular" />

        <h2>Com equipamento?</h2>
        <Select
          options={usaEquipamentoOptions}
          value={selectedUsaEquipamento}
          onChange={e => handleTreinoEmCasaChange(e)}
          name="usaEquipamento"
          placeholder="Sim ou Não"
        />

        <h2>Equipamento</h2>
        <Input
          type="text"
          name="equipamento"
          placeholder="Nome ou número do equipamento"
        />

        {/* <h2>Foto do Equipamento</h2>
        <Input
          type="text"
          name="fotoEquipamento"
          placeholder="link da imagem do equipamento"
        /> */}

        {/* <h2>Video - Execução</h2>
        <Input type="text" name="videoExemplo" placeholder="link do vídeo" /> */}

        <Button type="submit" width="200px">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};
export default FormExercicios;

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';

import {
  Container,
  AddExercises,
  SelectExercicio,
  ContainerSelect,
  ContainerSaveCancelButtons,
  ContainerExecucao,
  BoxInput,
} from './styles';
import api from '../../../services/api';

import Input from '../../../components/FormInputDefault';
import Select from '../../../components/SelectForm';
import Button from '../../../components/Button';
import BasicAccordionWithDelete from '../../../components/BasicAccordionWithDelete';
import { useToast } from '../../../hooks/toast';

interface ExercicioAdicionado {
  title: string;
  summary: string;
  contentDetails: ExercicioExecucao;
  exercicioId: number;
  nome: string;
  // exercicioExecucao: ExercicioExecucao;
}

interface SelectType {
  value: number;
  label: string;
}

const treinoAtivoOptions: SelectType[] = [
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' },
];

interface Props {
  formRef: React.Ref<FormHandles>;
  buttonText: string;
  editEnabled?: boolean;
  setEditEnabled?: any;
  handleSubmit: SubmitHandler;
  idAluno?: string;
  updateExercicios: (exericios: ExercicioAdicionado[]) => void;
  exerciciosFromDatabase?: any;
  dataInicioFromDb?: string;
  dataFimFromDb?: string;
  setDataInicioFromDb?: any;
  setDataFimFromDb?: any;
  treinoAtivoFromDb?: boolean;
  setClicouRemover?: any;
}

interface ExercicioExecucao {
  series?: string;
  repeticoes?: string;
  intervalo?: string;
  carga?: string;
}

const FormTreino: React.FC<Props> = props => {
  const history = useHistory();
  const { addToast } = useToast();

  const [selectedTreinoAtivo, setSelectedTreinoAtivo] = useState<SelectType>({
    value: 1,
    label: 'Sim',
  });
  const [exerciciosToSelect, setExerciciosToSelect] = useState<
    ExercicioAdicionado[]
  >([]);
  const [exercicioIdSelecionado, setExercicioIdSelecionado] = useState<any>();
  const [exerciciosAdicionadosState, setExerciciosAdicionadosState] = useState<
    ExercicioAdicionado[]
  >([]);
  const [carga, setCarga] = useState('');
  const [series, setSeries] = useState('');
  const [repeticoes, setRepeticoes] = useState('');
  const [intervalo, setIntervalo] = useState('');

  useEffect(() => {
    console.log(`props.treinoAtivoFromDb`, props.treinoAtivoFromDb);
    const treinoAtivoLocal = {
      value: props.treinoAtivoFromDb ? 1 : 0,
      label: props.treinoAtivoFromDb ? 'Sim' : 'Não',
    };
    setSelectedTreinoAtivo(treinoAtivoLocal);
  }, [props.treinoAtivoFromDb]);

  // load exercise already saved inside treino
  useEffect(() => {
    let mounted = true;
    const setExerciciosAdicionados = async (): Promise<void> => {
      if (!props.exerciciosFromDatabase) return;
      if (mounted) {
        setExerciciosAdicionadosState(props.exerciciosFromDatabase);
        // props.updateExercicios([props.exerciciosFromDatabase]);
      }
    };

    setExerciciosAdicionados();

    return () => {
      mounted = false;
    };
  }, [props.exerciciosFromDatabase]);

  // load exercices from database to be selected, except the items already inserted into the workout (treino)
  useEffect(() => {
    api.get(`exercicios`).then((response: any) => {
      const exerciciosToSelectLocal = [...response.data];
      exerciciosToSelectLocal.sort((a, b) => a.exercicioId - b.exercicioId);
      const difference = exerciciosToSelectLocal.filter(
        exercicio =>
          !exerciciosAdicionadosState.some(
            exerciciosAdicionado =>
              exerciciosAdicionado.exercicioId === exercicio.exercicioId,
          ),
      );
      setExerciciosToSelect(difference);
    });
  }, [exerciciosAdicionadosState]);

  const handleTreinoAtivoChange = useCallback(e => {
    console.log(`treinoAtivoChoice`, e);
    setSelectedTreinoAtivo(e);
  }, []);

  const handleAdicionarExercicioClick = useCallback(
    async exerciciosAdicionadosStateParam => {
      console.log(`exercicioIdSelecionado`, exercicioIdSelecionado);
      if (!exercicioIdSelecionado) {
        addToast({
          type: 'info',
          title: `Atenção: Selecione um exercíco para adicionar`,
          description:
            'Você precisa selecionar um exercício para adicinar ao treino.',
        });
        return;
      }
      // aqui vai adicionar um objeto desse jeito {exercicioId: 1} em um array de objetos
      // desse pra depois adicionar o array inteiro no treino, quando for salvar o treino

      await api
        .get(`exercicios/${exercicioIdSelecionado.value}`)
        .then((response: any) => {
          const exercicioSelecionadoLocal: ExercicioAdicionado = {
            title: response.data?.nome,
            summary: response.data?.nome,
            // contentDetais: response.data?.nome,
            exercicioId: response.data?.exercicioId,
            nome: response.data?.nome,
            contentDetails: {
              series,
              repeticoes,
              intervalo,
              carga,
            },
          };

          const exerciciosAdicionadosLocal: ExercicioAdicionado[] = [];
          exerciciosAdicionadosLocal.push(exercicioSelecionadoLocal);

          setExerciciosAdicionadosState(prevState => [
            ...prevState,
            ...exerciciosAdicionadosLocal,
          ]);

          const exerciciosAdicionadosToShow = [
            ...exerciciosAdicionadosLocal,
            ...exerciciosAdicionadosStateParam,
          ];

          console.log(
            `exerciciosAdicionadosToShow`,
            exerciciosAdicionadosToShow,
          );

          props.updateExercicios([...exerciciosAdicionadosToShow]);

          setExerciciosToSelect(prevState => [
            ...prevState.filter(
              exercicioRemover =>
                exercicioRemover.exercicioId !== exercicioIdSelecionado,
            ),
          ]);
          setExercicioIdSelecionado(null);
        });
    },
    [
      addToast,
      carga,
      exercicioIdSelecionado,
      intervalo,
      props,
      repeticoes,
      series,
    ],
  );

  const handleChange = useCallback(value => {
    setExercicioIdSelecionado(value);
  }, []);

  const handleDataInicioChange = useCallback(
    e => {
      e.preventDefault();
      const { name, value } = e.target;

      props.setDataInicioFromDb(value);
    },
    [props],
  );

  const handleDataFimChange = useCallback(
    e => {
      e.preventDefault();
      const { name, value } = e.target;

      props.setDataFimFromDb(value);
    },
    [props],
  );

  const handleDelete = (item: ExercicioAdicionado): void => {
    props.setClicouRemover(true);
    console.log(`index`, item);
    // filter exerciciosAdicionadosState where index.contentDetails !== exercicio.exercicioId
    const exerciciosAdicionadosStateFiltered =
      exerciciosAdicionadosState.filter(
        exercicio => exercicio.exercicioId !== Number(item.exercicioId),
      );
    console.log(
      `exerciciosAdicionadosStateFiltered`,
      exerciciosAdicionadosStateFiltered,
    );

    setExerciciosAdicionadosState(exerciciosAdicionadosStateFiltered);

    const exerciciosAdicionadosToShow = [...exerciciosAdicionadosStateFiltered];
    console.log(`exerciciosAdicionadosToShow`, exerciciosAdicionadosToShow);
    props.updateExercicios([...exerciciosAdicionadosToShow]);

    setExerciciosToSelect(prevState => [...prevState, item]);
  };

  return (
    <Container>
      <Form ref={props.formRef} onSubmit={props.handleSubmit}>
        <>
          <h3>Nome</h3>
          <Input
            type="text"
            name="nome"
            placeholder="Digite um nome para o treino"
          />

          <h3>Descrição</h3>
          <Input
            type="text"
            name="descricao"
            placeholder="Digite uma descrição para o treino"
          />

          <h3>Treino Ativo?</h3>
          <Select
            options={treinoAtivoOptions}
            value={selectedTreinoAtivo}
            getOptionLabel={({ label }: any) => label}
            getOptionValue={({ value }: any) => value}
            onChange={e => handleTreinoAtivoChange(e)}
            name="ativo"
            placeholder="Sim ou Não"
          />

          <h3>Data de Início</h3>
          <Input
            type="date"
            isDisabled={!props.editEnabled}
            disabled={!props.editEnabled}
            name="dataInicio"
            value={props.dataInicioFromDb}
            onChange={handleDataInicioChange}
          />

          <h3>Data de Vencimento</h3>
          <Input
            type="date"
            isDisabled={!props.editEnabled}
            disabled={!props.editEnabled}
            name="dataVencimento"
            value={props.dataFimFromDb}
            onChange={handleDataFimChange}
          />

          <h3>Observação</h3>
          <Input
            type="text"
            name="observacao"
            placeholder="Digite uma observação para o treino"
          />
        </>
        <h3>Exercícios</h3>

        <AddExercises>
          <ContainerSelect>
            <SelectExercicio
              name="mySelect"
              value={
                exercicioIdSelecionado
                  ? exercicioIdSelecionado.label
                  : 'Select...'
              }
              getOptionLabel={({ label }: any) => label}
              getOptionValue={({ value }: any) => value}
              onChange={(option: any) => handleChange(option)}
              options={exerciciosToSelect.map(exe => {
                return { value: exe.exercicioId, label: exe.nome };
              })}
              placeholder={
                exercicioIdSelecionado
                  ? exercicioIdSelecionado.label
                  : 'Select...'
              }
            />
          </ContainerSelect>
          <ContainerExecucao>
            <BoxInput>
              <h3>Séries</h3>
              <Input
                type="number"
                isDisabled={!props.editEnabled}
                disabled={!props.editEnabled}
                name="series"
                value={series}
                onChange={e => setSeries(e.target.value)}
              />
            </BoxInput>
            <BoxInput>
              <h3>Repetições</h3>
              <Input
                type="number"
                isDisabled={!props.editEnabled}
                disabled={!props.editEnabled}
                name="repeticoes"
                value={repeticoes}
                onChange={e => setRepeticoes(e.target.value)}
              />
            </BoxInput>
            <BoxInput>
              <h3>Intervalo</h3>
              <Input
                type="string"
                isDisabled={!props.editEnabled}
                disabled={!props.editEnabled}
                name="intervalo"
                value={intervalo}
                onChange={e => setIntervalo(e.target.value)}
              />
            </BoxInput>
            <BoxInput>
              <h3>Carga</h3>
              <Input
                type="string"
                isDisabled={!props.editEnabled}
                disabled={!props.editEnabled}
                name="carga"
                value={carga}
                onChange={e => setCarga(e.target.value)}
              />
            </BoxInput>
            <Button
              type="button"
              width="200px"
              marginLeft="30px"
              marginRight="0px"
              marginTop="0px"
              onClick={() =>
                handleAdicionarExercicioClick(exerciciosAdicionadosState)
              }
            >
              Adicionar
            </Button>
          </ContainerExecucao>
        </AddExercises>
        <br />

        <BasicAccordionWithDelete
          items={exerciciosAdicionadosState.map(
            (exercicio: ExercicioAdicionado) => {
              return {
                title: `${exercicio.title}`,
                summary: exercicio.summary,
                exercicioId: exercicio.exercicioId,
                contentDetails: `
                Séries: ${exercicio.contentDetails?.series}
                Repetições: ${exercicio.contentDetails?.repeticoes}
                Carga: ${exercicio.contentDetails?.carga}
                Intervalo: ${exercicio.contentDetails?.intervalo}`,
              };
            },
          )}
          onDelete={(item: any) => handleDelete(item)}
        />
        <br />
        {props.editEnabled && (
          <ContainerSaveCancelButtons>
            <Button type="submit" width="200px" marginRight="40px">
              Salvar
            </Button>

            {props.buttonText === 'Edit' && (
              <Button
                type="button"
                width="200px"
                onClick={() =>
                  history.push(`/alunos/alunopanel/${props.idAluno}`)
                }
              >
                Cancelar
              </Button>
            )}

            {props.buttonText === 'Create' && (
              <Button
                type="button"
                width="200px"
                onClick={() => {
                  history.push(`/alunos/alunopanel/${props.idAluno}`);
                }}
              >
                Cancelar
              </Button>
            )}
          </ContainerSaveCancelButtons>
        )}
      </Form>
    </Container>
  );
};
export default FormTreino;

/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import api from '../../../services/api';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Container } from './styles';
import FormTreino from '../Form';
import BackButton from '../../../components/BackButton';

interface Treino {
  alunoId: number;
  nome: string;
  descricao: string;
  ativo: number;
  tipo: string;
  dataInicio: Date;
  dataFim: Date;
  dataVencimento: Date;
  carga: string;
  seriesFicha: string;
  repeticoesFicha: string;
  intervaloFicha: string;
  observacao: string;
  imagemTreino: string;
  exercicios: Exercicio[];
  created_by: string;
  created_at: Date;
  updated_by: string;
  updated_at: Date;
}

interface Exercicio {
  exercicioId: number;
  nome: string;
  categoria: string;
  grupoMuscular: string;
  usaEquipamento: boolean;
  equipameo: string;
  created_by: string;
  createdat: Date;
  updated_by: string;
  updated_at: Date;
}

interface ParamTypes {
  id: string;
  treinoId: string;
}

const EditTreino: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id, treinoId } = useParams<ParamTypes>();
  const history = useHistory();
  const { addToast } = useToast();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [exerciciosFromDatabase, setExerciciosFromDatabase] = useState<any[]>(
    [],
  );
  const [dataInicioFromDb, setDataInicioFromDb] = useState('');
  const [dateFimFromDb, setDataFimFromDb] = useState('');
  const [treinoAtivoFromDb, setTreinoAtivoFromDb] = useState<boolean>(false);
  const [exerciciosAdicionadosParaSalvar, setExerciciosAdicionadosParaSalvar] =
    useState<any>([]);

  const [clicouRemover, setClicouRemover] = useState<boolean>(false);

  useEffect(() => {
    api.get(`treinos/aluno/${id}/treino/${treinoId}`).then((response: any) => {
      setExerciciosFromDatabase(response.data.exercicios);
      setEditEnabled(false);
      setTreinoAtivoFromDb(response.data.ativo);
      formRef.current?.setFieldValue('nome', response.data.nome);
      formRef.current?.setFieldValue('descricao', response.data.descricao);
      // formRef.current?.setFieldValue('ativo', {
      //   value: response.data.ativo ? 0 : 1,
      //   label: response.data.ativo ? 'Sim' : 'Não',
      // });
      formRef.current?.setFieldValue('tipo', response.data.tipo);
      formRef.current?.setFieldValue('dataInicio', response.data.dataInicio);
      formRef.current?.setFieldValue(
        'intervaloFicha',
        response.data.intervaloFicha,
      );
      formRef.current?.setFieldValue('seriesFicha', response.data.seriesFicha);
      formRef.current?.setFieldValue(
        'repeticoesFicha',
        response.data.repeticoesFicha,
      );

      formRef.current?.setFieldValue(
        'dataVencimento',
        response.data.dataVencimento,
      );
      formRef.current?.setFieldValue('observacao', response.data.observacao);
      formRef.current?.setFieldValue(
        'grupoMuscular',
        response.data.grupoMuscular,
      );

      let dateTest = moment.utc(response.data.dataInicio).format('YYYY-MM-DD');
      setDataInicioFromDb(dateTest);
      dateTest = moment.utc(response.data.dataVencimento).format('YYYY-MM-DD');
      setDataFimFromDb(dateTest);
    });
  }, [id, treinoId]);

  const updateExercicios = useCallback((exercicios: any[]): void => {
    setExerciciosAdicionadosParaSalvar(exercicios);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      console.log(`data para salvar`, data);
      delete data.carga;
      delete data.series;
      delete data.repeticoes;
      delete data.intervalo;
      try {
        formRef.current?.setErrors({});

        console.log(
          `exerciciosAdicionadosParaSalvar`,
          exerciciosAdicionadosParaSalvar,
        );

        console.log(`exerciciosFromDatabase`, exerciciosFromDatabase);

        const editTreino = {
          ...data,
          alunoId: id,
          ativo: data.ativo === 1,
          observacao: data.observacao,
          exercicios:
            exerciciosAdicionadosParaSalvar.length === 0 && !clicouRemover
              ? exerciciosFromDatabase
              : exerciciosAdicionadosParaSalvar,
        };

        const response = await api.put(`/treinos/${treinoId}`, editTreino);

        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Treino alterado com sucesso!',
        });

        history.push(`/alunos/alunopanel/${response.data.alunoId}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: `Registration Error`,
          description:
            'Ocorreu um erro ao criar o treino, por favor tente novamente.',
        });
      }
    },
    [
      addToast,
      clicouRemover,
      exerciciosAdicionadosParaSalvar,
      exerciciosFromDatabase,
      history,
      id,
      treinoId,
    ],
  );

  return (
    <Container>
      <h1>Editar treino</h1>
      <BackButton text="Voltar" url={`/alunos/alunopanel/${id}`} />
      <FormTreino
        formRef={formRef}
        handleSubmit={handleSubmit}
        idAluno={id}
        setEditEnabled={setEditEnabled}
        editEnabled={true}
        buttonText="Edit"
        dataInicioFromDb={dataInicioFromDb}
        dataFimFromDb={dateFimFromDb}
        setDataInicioFromDb={setDataInicioFromDb}
        setDataFimFromDb={setDataFimFromDb}
        updateExercicios={updateExercicios}
        exerciciosFromDatabase={exerciciosFromDatabase}
        treinoAtivoFromDb={treinoAtivoFromDb}
        setClicouRemover={setClicouRemover}
      ></FormTreino>
    </Container>
  );
};

export default EditTreino;

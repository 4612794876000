import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  position: relative;
  width: 100%;
  height: 100px;
  margin: 15px;
  background: #eaeaea;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-start;
`;

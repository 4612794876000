import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';

import { TbBarbell } from 'react-icons/tb';
import { GiWeightLiftingUp } from 'react-icons/gi';

import { FiPower, FiUsers } from 'react-icons/fi';

import 'react-pro-sidebar/dist/css/styles.css';
import {
  SidebarHeaderCustom,
  SidebarHeaderContent,
  SidebarFooterContent,
  Button,
} from './styles';

import { useAuth } from '../../hooks/auth';

import logoImg from '../../assets/arthur_pequena_fundo_branco.png';

interface SideBarProps {
  name: string;
}

const SideBar: React.FC<SideBarProps> = ({ name }) => {
  const { signOut } = useAuth();
  const [currentLocation, setCurrentLocation] = useState<string>();

  useEffect(() => {
    setCurrentLocation(window.location.pathname);
  }, []);

  const handleMenuClick = useCallback(() => {
    setCurrentLocation(window.location.pathname);
  }, []);

  return (
    // visibility: collapse;
    // position: absolute;

    <ProSidebar width="240px">
      <SidebarHeaderCustom>
        <SidebarHeaderContent>{name}</SidebarHeaderContent>
        <Button type="button" onClick={signOut}>
          <FiPower color={'#fff'} size={20} />
        </Button>
      </SidebarHeaderCustom>

      <SidebarContent>
        <Menu iconShape="circle">
          <SubMenu title="Alunos" icon={<FiUsers />}>
            <MenuItem
              active={currentLocation === '/alunos/new'}
              onClick={handleMenuClick}
            >
              Novo Aluno
              <Link to="/alunos/new" />
            </MenuItem>
            <MenuItem
              active={currentLocation === '/alunos'}
              onClick={handleMenuClick}
            >
              Lista
              <Link to="/alunos" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Exercícios" icon={<TbBarbell />}>
            <MenuItem
              active={currentLocation === '/exercicios/new'}
              onClick={handleMenuClick}
            >
              Novo Exercício
              <Link
                to={{
                  pathname: `/exercicios/new`,
                }}
              />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/exercicios'}
              onClick={handleMenuClick}
            >
              Lista
              <Link to="/exercicios" />
            </MenuItem>
          </SubMenu>

          {/* <SubMenu title="Painel do Aluno" icon={<GiWeightLiftingUp />}>
            <MenuItem
              active={currentLocation === '/AlunoPainel'}
              onClick={handleMenuClick}
            >
              Painel do Aluno
              <Link to="/alunopainel" />
            </MenuItem>
          </SubMenu> */}

          {/* <SubMenu title="Planos de Treino" icon={<FaClipboardList />}>
            <MenuItem
              active={currentLocation === '/customers/new'}
              onClick={handleMenuClick}
            >
              Novo Plano de Treino
              <Link to="/customers/new" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/customers'}
              onClick={handleMenuClick}
            >
              Lista
              <Link to="/customers" />
            </MenuItem>
          </SubMenu> */}

          {/* <SubMenu title="Projetos" icon={<AiOutlineFundProjectionScreen />}>
            <MenuItem
              active={currentLocation === '/projects/new'}
              onClick={handleMenuClick}
            >
              Novo Projeto
              <Link to="/projects/new" />
            </MenuItem>

            <MenuItem
              active={currentLocation === '/projects'}
              onClick={handleMenuClick}
            >
              Lista
              <Link to="/projects" />
            </MenuItem>
          </SubMenu> */}
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <SidebarFooterContent>
          <img src={logoImg} alt="©️ Minen Tecnologia - 2023" width="200" />
        </SidebarFooterContent>
        <span style={{ marginLeft: 45, fontSize: 12 }}>
          ©️ Minen Tecnologia - 2023
        </span>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideBar;

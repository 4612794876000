import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Alunos from '../pages/Alunos';
import CreateAlunos from '../pages/Alunos/New';
import EditAlunos from '../pages/Alunos/Edit';
import AlunoPanel from '../pages/Alunos/Panel';

import Exercicios from '../pages/Exercicios';
import CreateExercicios from '../pages/Exercicios/New';
import EditExercicios from '../pages/Exercicios/Edit';

import CreateTreinos from '../pages/Treinos/New';
import EditTreino from '../pages/Treinos/Edit';

import AlunoFicha from '../pages/AlunoFicha';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/alunos" exact component={Alunos} isPrivate />
    <Route path="/alunos/new" component={CreateAlunos} isPrivate />
    <Route path="/alunos/edit/:id" component={EditAlunos} isPrivate />
    <Route path="/alunos/alunopanel/:id" component={AlunoPanel} isPrivate />

    <Route path="/exercicios" exact component={Exercicios} isPrivate />
    <Route path="/exercicios/new" component={CreateExercicios} isPrivate />
    <Route path="/exercicios/edit/:id" component={EditExercicios} isPrivate />
    {/*
    <Route path="/alunopainel" exact component={AlunoPainel} isPrivate /> */}
    <Route path="/alunos/:id/treinos/new" component={CreateTreinos} isPrivate />
    <Route
      path="/alunos/:id/treinos/edit/:treinoId"
      component={EditTreino}
      isPrivate
    />

    <Route
      path="/alunoficha/:id/:tabId"
      exact
      component={AlunoFicha}
      isPrivate
    />
  </Switch>
);

export default Routes;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment';
import api from '../../../services/api';

import FormAluno from '../Form';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import { Button, Container } from './styles';
import BackButton from '../../../components/BackButton';

interface SelectType {
  value: number;
  label: string;
}

interface Aluno {
  nome: string;
  telefone: string;
  endereco: string;
  dataNascimento: any;
  email: string;
  treinoEmCasa: SelectType;
  nomeAcademia: number;
}

interface ParamTypes {
  id: string;
}

const EditAluno: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<ParamTypes>();
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [dateFromDb, setDateFromDb] = useState('');

  useEffect(() => {
    api.get(`alunos/${id}`).then((response: any) => {
      setEditEnabled(false);
      console.log(`test loading edit`, response.data);

      formRef.current?.setFieldValue('nome', response.data.nome);
      formRef.current?.setFieldValue('telefone', response.data.telefone);
      formRef.current?.setFieldValue('email', response.data.email);
      formRef.current?.setFieldValue('endereco', response.data.endereco);
      formRef.current?.setFieldValue(
        'nomeAcademia',
        response.data.nomeAcademia,
      );

      const dateTest = moment
        .utc(response.data.dataNascimento)
        .format('YYYY-MM-DD');

      setDateFromDb(dateTest);
      formRef.current?.setFieldValue('dataNascimento', dateTest);

      formRef.current?.setFieldValue('treinoEmCasa', {
        value: response.data.treinoEmCasa,
        label: response.data.treinoEmCasa ? 'Sim' : 'Não',
      });
    });
  }, [id]);

  const handleSubmit = useCallback(
    async (data: Aluno) => {
      console.log(`Submeteu Aluno`);
      try {
        formRef.current?.setErrors({});
        const editAluno = {
          ...data,
          treinoEmCasa: !!data.treinoEmCasa,
          dataNascimento: dateFromDb,
        };
        const response = await api.put(`/alunos/${id}`, editAluno);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'Aluno alterado com sucesso!',
        });
        setEditEnabled(false);
        history.push('/alunos', {
          alunoId: response.data.alunoId,
          nome: response.data.nome,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          console.log('err', err);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setEditEnabled(false);
          return;
        }
        setEditEnabled(false);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'Erro ao salvar as alterações. por favor, tente novamente.',
        });
      }
    },
    [addToast, dateFromDb, history, id],
  );

  return (
    <Container>
      <h1>{editEnabled ? `Editar aluno` : `Dados do Aluno`} </h1>
      {!editEnabled && <BackButton text="Voltar" />}
      {!editEnabled && (
        <div>
          <Button
            variant="contained"
            type="button"
            onClick={() => setEditEnabled(true)}
          >
            Editar Aluno
          </Button>
        </div>
      )}
      <FormAluno
        formRef={formRef}
        handleSubmit={handleSubmit}
        setEditEnabled={setEditEnabled}
        editEnabled={editEnabled}
        buttonText="Edit"
        dateFromDb={dateFromDb}
        setDateFromDb={setDateFromDb}
      ></FormAluno>
    </Container>
  );
};
export default EditAluno;
